import {
	IonButton,
	IonContent,
	IonIcon,
	IonImg,
	IonInput,
	IonPage,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonSpinner,
	IonText,
	IonToolbar,
	isPlatform,
} from '@ionic/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { motion } from 'framer-motion';
import { checkmark } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { STRIPE_KEY } from '../../config/production';
import closeIcon from '../../img/arrow-back-outline.svg';
import GroupWalletPaymentBG from '../../img/group-wallet-payment-bg.svg';
import GroupWalletPayment from '../../img/group-wallet-payment.svg';
import { walletActions } from '../../redux/actions';
import AddressCountries from '../../shared/addressCountries';
import './activateWalletPage.css';
import PaymentCard from './paymentCard';
interface ContainerProps {
	prop?: RouteComponentProps;
	activateStep?: boolean;
}
const ActivateWalletPage: React.FC<ContainerProps> = (props) => {
	const dispatch = useDispatch();
	const { prop, activateStep } = props;
	const [selectedPayment, setSelectedPayment] = useState(0);
	const [selectedPaymentSecond, setSelectedPaymentSecond] = useState(false);
	const [openModal, isModalOpen] = useState(false);
	const [dismissModal, isDismissModal] = useState(false);
	let stripePromise: any;
	const [payment, setPayment] = useState(false);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const [cardName, setCardName] = useState('');
	const [checkoutError, setCheckoutError] = useState('');
	const [region, setRegion] = useState('US');
	const [zip, setZip] = useState('');
	const [paymentProgress, setPaymentProgress] = useState(false);
	const [paymentCompleted, setPaymentCompleted] = useState(false);
	const userEmail: string = !activateStep ? JSON.parse(localStorage.getItem('NewAccountEmail')!) : data?.email;
	console.log(activateStep);
	useEffect(() => {
		if (prop?.history?.location?.state !== null) {
			const data = prop?.history?.location?.state as any;
			setSelectedPayment(data?.optionSelected);
			setSelectedPaymentSecond(data?.providerType);
		}
	}, [prop?.history?.location?.state]);
	const redirectToVerify = async () => {
		if (!activateStep) {
			localStorage.setItem('NuCoUserStage', JSON.stringify('building'));
			localStorage.removeItem('NewAccountEmail');
			navigate('/building-wallet');
		} else {
			const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
			await dispatch(walletActions.authorization(tmpUserToken));
			const timer = setTimeout(() => {
				navigate('/tabs/wallet');
			}, 3000);
			return () => clearTimeout(timer);
		}
	};
	const navigate = (route: string) => {
		prop?.history.push(route);
	};

	const getStripe = () => {
		if (!stripePromise) {
			stripePromise = loadStripe(STRIPE_KEY!);
		}
		return stripePromise;
	};
	const clearModal = () => {
		isDismissModal(!dismissModal);
		isModalOpen(!openModal);
	};
	async function progressPayment(value: boolean, completed: boolean) {
		await sleep(1000);
		setPaymentProgress(value);
		if (completed) {
			setPaymentCompleted(completed);
		}
	}
	function sleep(ms: any) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}
	const handleCardDetailsChange = (event: any) => {
		event.error ? setCheckoutError(event.error.message) : setCheckoutError('');
	};
	const errorOnPayment = () => {
		setPaymentProgress(false);
		setPaymentCompleted(false);
	};
	return (
		<IonPage className="loginPage">
			<IonContent fullscreen className="background-payment max-550 ion-padding-bottom ">
				<IonToolbar
					className="no-background ion-margin-top"
					onClick={() => (!activateStep ? navigate('/payment-wallet') : navigate('/tabs/verify-identity'))}>
					<span className="back-payment ion-margin-top">
						<IonImg src={closeIcon} className="close-modal-img" onClick={() => isDismissModal(!dismissModal)} />
						Back
					</span>
				</IonToolbar>
				<div className="container-close-modal">
					<IonImg src={GroupWalletPayment} className="img-wallet-payment" />
					<IonImg src={GroupWalletPaymentBG} className="img-wallet-payment-bg" />
				</div>
				<IonRow className="pay-version-text">
					{selectedPayment === 2 || selectedPayment === 3 ? 'Nuco 12 month license' : 'Nuco 6 month license'}
				</IonRow>
				<IonRow className="pay-version-title">
					{selectedPaymentSecond
						? selectedPayment === 2
							? '$39.99'
							: selectedPayment === 1
							? '$27.07'
							: '$49.99'
						: selectedPayment === 2
						? activateStep
							? '$75.00'
							: '$75.00'
						: selectedPayment === 1
						? activateStep
							? '$51.00'
							: '$51.00'
						: activateStep
						? '$75.00'
						: '$94.00'}
				</IonRow>
				{checkoutError && (
					<div className="email-padding-payment error-payment">
						<span>{checkoutError}</span>
					</div>
				)}

				<div className="email-payment">
					<span>Email</span>
				</div>
				<div className="email-value-payment email-padding-payment">
					<IonText>{userEmail || 'Email'} </IonText>
				</div>
				<div className="email-payment">
					<span>Card Number</span>
				</div>
				<div className="ion-margin email-value-payment">
					{' '}
					<Elements stripe={getStripe()}>
						<PaymentCard
							redirectToVerify={redirectToVerify}
							amount={
								selectedPayment === 2
									? activateStep
										? '7500'
										: '7500'
									: selectedPayment === 1
									? activateStep
										? '51'
										: '5100'
									: activateStep
									? '7500'
									: '9400'
							}
							clearModal={clearModal}
							dismiss={dismissModal}
							handleCardDetailsChange={handleCardDetailsChange}
							payment={payment}
							cardName={cardName}
							zip={zip}
							region={region}
							progressPayment={progressPayment}
							errorOnPayment={errorOnPayment}
							selectedPayment={selectedPayment}
							selectedPaymentSecond={selectedPaymentSecond}
						/>
					</Elements>
				</div>
				<div className="email-payment">
					<span>Name on card</span>
				</div>
				<div className="email-value-payment email-padding-payment">
					<IonInput onIonChange={(e: any) => setCardName(e.target.value)}></IonInput>
				</div>
				<div className="email-payment">
					<span>Country or region</span>
				</div>
				<div className="email-value-payment">
					<div>
						<IonSelect value={'US'} onIonChange={(e) => setRegion(e.detail.value)}>
							{AddressCountries.forSelect.map((option, i) => (
								<IonSelectOption value={option.value} key={i}>
									{option.label}
								</IonSelectOption>
							))}
						</IonSelect>
					</div>
					<div className="border-zip-payment ">
						<IonInput
							placeholder="ZIP"
							onIonChange={(e: any) => setZip(e.target.value)}
							className="email-padding-payment"></IonInput>
					</div>
				</div>
				{!paymentProgress && !paymentCompleted && !selectedPaymentSecond && (
					<div className={`flex-center-subs-amount ${isPlatform('mobile') ? 'btn-pay-mb' : ''}`}>
						<IonButton
							onClick={() => setPayment(true)}
							color={'secondary'}
							shape="round"
							type="button"
							className="sign-in-width-subs div-btn-pay-margin payment-button ion-margin-top">
							{`Pay ${
								selectedPayment === 2
									? activateStep
										? '$75'
										: '$75'
									: selectedPayment === 1
									? activateStep
										? '$51'
										: '$51'
									: activateStep
									? '$75'
									: '$94'
							}`}
						</IonButton>
					</div>
				)}

				{!paymentProgress && !paymentCompleted && selectedPaymentSecond && (
					<div className={`flex-center-subs-amount ${isPlatform('mobile') ? 'btn-pay-mb' : ''}`}>
						<IonButton
							onClick={() => setPayment(true)}
							color={'secondary'}
							shape="round"
							type="button"
							className="sign-in-width-subs div-btn-pay-margin payment-button ion-margin-top">
							{`Pay ${selectedPayment === 2 ? '$39.99' : selectedPayment === 1 ? '$27.07' : '$49.99'}`}
						</IonButton>
					</div>
				)}
			</IonContent>
			{paymentProgress && (
				<div className="modal-overlay">
					<div className="modal-content">
						<div className="center-payment">
							<IonSpinner name="circles"></IonSpinner>
						</div>
					</div>
				</div>
			)}
			{paymentCompleted && (
				<motion.div
					className="center-payment modal-overlay"
					animate={{ scale: [1, 1.5, 1], opacity: [0, 1, 1] }}
					transition={{ duration: 1 }}
					style={{ display: 'flex', alignItems: 'center' }}>
					<div className="modal-content modal-content-completed">
						<div className="center-payment-completed">
							<IonIcon icon={checkmark} style={{ color: 'green', fontSize: '2em' }} />
							<p>Done</p>
						</div>
					</div>
				</motion.div>
			)}
		</IonPage>
	);
};

export default ActivateWalletPage;
